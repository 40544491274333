import "../styles/main.sass";

import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

import "./bootstrap.config";

import Quagga from "@ericblade/quagga2";

import Chart from "chart.js/auto";

// import and register htmx to the window element
import htmx from "htmx.org";
window.htmx = htmx;

function run() {
    window.addEventListener("load", function () {
        const cartByTimeCharts = document.querySelectorAll(".cartByTimeChart");
        cartByTimeCharts.forEach(function (element) {
            const chartData = JSON.parse(
                document.getElementById(element.dataset.dataElement).textContent
            );
            new Chart(element, {
                type: "bar",
                data: {
                    datasets: [
                        {
                            data: chartData,
                        },
                    ],
                },
                options: {
                    parsing: {
                        xAxisKey: "hour",
                        yAxisKey: "count",
                    },
                    plugins: { legend: { display: false } },
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                precision: 0,
                            },
                        },
                        x: {
                            type: "linear",
                            min: 0,
                            max: 23,
                            ticks: {
                                stepSize: 1,
                            },
                        },
                    },
                },
            });
        });

        const autoselectInputs = document.querySelectorAll(
            '*[data-autoselect="true"]'
        );
        autoselectInputs.forEach(function (input) {
            input.addEventListener("focus", function (event) {
                event.target.select();
            });
        });

        const toggleBarcodeScanner = document.querySelectorAll(
            '*[data-toggle="barcode-scanner"]'
        );
        toggleBarcodeScanner.forEach(function (button) {
            if (
                navigator.mediaDevices &&
                typeof navigator.mediaDevices.getUserMedia === "function"
            ) {
                button.addEventListener("click", function (event) {
                    if (isScanActive === false) {
                        startBarcodeScanner(button);
                    } else {
                        stopBarcodeScanner();
                    }
                    event.preventDefault();
                    return false;
                });
            } else {
                button.disabled = true;
            }
        });
    });
}

let isScanActive = false;

function stopBarcodeScanner() {
    Quagga.stop();
    isScanActive = false;
}

function startBarcodeScanner(button) {
    Quagga.init(
        {
            debug: false,
            inputStream: {
                name: "Live",
                type: "LiveStream",
                target: document.querySelector("#barcode-scanner-viewport"),
                constraints: {
                    width: 1280,
                    height: 720,
                    facingMode: "environment",
                },
                area: {
                    top: "0%",
                    right: "0%",
                    left: "0%",
                    bottom: "0%",
                },
            },
            decoder: {
                readers: ["code_128_reader"],
                multiple: false,
            },
            locator: {
                halfSample: true,
                patchSize: "large",
            },
        },
        function (err) {
            if (err) {
                console.error(err);
                return;
            }

            Quagga.start();

            const track = Quagga.CameraAccess.getActiveTrack();
            let capabilities = {};
            if (track && typeof track.getCapabilities === "function") {
                capabilities = track.getCapabilities();
            }

            const toggleTorch = document.querySelectorAll(
                '*[data-toggle="torch"]'
            );
            toggleTorch.forEach(function (button) {
                if (capabilities.torch) {
                    button.removeAttribute("disabled");
                }
                button.addEventListener("click", function (event) {
                    const setting = track.getSettings();
                    if (capabilities.torch) {
                        track.applyConstraints({
                            advanced: [{ torch: !setting.torch }],
                        });
                    }
                });
            });
        }
    );

    const resultInput = document.querySelector(button.dataset.resultTarget);
    const form = resultInput.closest("form");
    Quagga.onDetected(function (result) {
        const code = result.codeResult.code;
        stopBarcodeScanner();
        resultInput.value = code;
        form.submit();
    });
}

run();
